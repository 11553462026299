import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import { GenericContext } from '@eva/emf/app/shared/constants';
import { copy, stringifyError } from '@eva/emf/app/shared/functions';
import CustomField from '@eva/emf/app/shared/ui/CustomFields/CustomField';
import { FormCustomFields } from '@eva/emf/app/shared/ui/CustomFields/FormCustomFields';
import PopperTooltip from '@eva/emf/app/shared/ui/Popper/PopperTooltip';
import { requestBackend } from '@eva/emf/app/utils/request';

import { updateUserProfile } from 'containers/App/actions';

import { CardCustomFieldsType } from './types';

export const getPatchedJsonSchema = (originalJsonSchema, uiSchema, fieldKeys) => {
  const myCandidateProfileConfig = uiSchema['#myCandidateProfileConfig'];
  const jsonSchema = copy(originalJsonSchema);

  const usedFieldKeys = myCandidateProfileConfig
    ? myCandidateProfileConfig.reduce((prev, cur) => [...prev, ...cur.fieldKeys], [])
    : [];
  jsonSchema.properties = Object.keys(jsonSchema.properties)
    .filter((jsonSchemaKey) => {
      if (fieldKeys) {
        return fieldKeys.includes(jsonSchemaKey);
      } else if (myCandidateProfileConfig) {
        return !usedFieldKeys.includes(jsonSchemaKey);
      }
      return true;
    })
    .reduce(
      (prev, cur) => ({
        ...prev,
        [cur]: jsonSchema.properties[cur],
      }),
      {},
    );

  return jsonSchema;
};

export const CardCustomFields: FC<CardCustomFieldsType> = (props) => {
  const dispatch = useDispatch();
  const {
    settings: { customFieldsModel },

    isAllowedOperation,
  } = React.useContext(GenericContext);
  const { entity, cardLabel = 'Additional information', fieldKeys, previewMode, hideEmpty } = props;

  const { customFields } = entity;
  const { jsonSchema, uiSchema } = customFieldsModel;

  const [editMode, setEditMode] = React.useState(false);
  const [error, setError] = React.useState('');

  const { current: abortController } = React.useRef(new AbortController());
  React.useEffect(() => () => abortController.abort(), [abortController]);

  const submitHandler = React.useCallback(
    ({ formData }) =>
      requestBackend('/my/candidate-profile/custom-fields', {
        method: 'PUT',
        body: JSON.stringify({ ...customFields, ...formData }),
        signal: abortController.signal,
      }).then(
        () => {
          if (abortController.signal.aborted) {
            return;
          }
          dispatch(
            updateUserProfile({
              customFields: { ...customFields, ...formData },
            }),
          );
          setEditMode(false);
        },
        (err) => {
          setError(stringifyError(err));
        },
      ),
    [customFields, abortController.signal, dispatch],
  );

  const patchedJsonSchema = getPatchedJsonSchema(jsonSchema, uiSchema, fieldKeys);
  const jsonSchemaKeys = Object.keys(patchedJsonSchema.properties);

  if (editMode) {
    return (
      <div>
        {error && <div className="text-danger">{error}</div>}
        <h4>{cardLabel}</h4>
        <FormCustomFields
          customFields={customFields}
          jsonSchema={patchedJsonSchema}
          uiSchema={uiSchema}
          switchEditMode={setEditMode}
          submitHandler={submitHandler}
        />
      </div>
    );
  }

  return (
    <div className="section">
      {error && <div className="text-danger">{error}</div>}
      {isAllowedOperation('myProfile-customFields-update') && (
        <span className="pull-right">
          <PopperTooltip placement="top" overlay={translate('Edit')}>
            <button
              type="button"
              className="btn-box-tool btn btn-sm pencil-edit-btn"
              onClick={() => setEditMode(true)}
              style={{
                padding: '0 5px',
                lineHeight: '1.5em',
                height: 'auto',
              }}
            >
              <i className="lnr lnr-pencil" />
            </button>
          </PopperTooltip>
        </span>
      )}
      <h4>{cardLabel}</h4>
      {jsonSchemaKeys
        .filter((jsonSchemaKey) => {
          if (!hideEmpty) {
            return true;
          }
          const value = customFields[jsonSchemaKey];
          return Array.isArray(value) ? value.length : value;
        })
        .map((jsonSchemaKey) => (
          <CustomField
            key={jsonSchemaKey}
            jsonSchema={patchedJsonSchema}
            uiSchema={uiSchema}
            customFields={customFields}
            jsonSchemaKey={jsonSchemaKey}
            previewMode={previewMode}
            showDescription
          />
        ))}
    </div>
  );
};
