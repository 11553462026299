import React, { FC } from 'react';

import { GenericContext } from '@eva/emf/app/shared/constants';

import { getPatchedJsonSchema } from './CardCustomFields';
import { PreviewCustomFieldsType } from './types';

export const PreviewCustomFields: FC<PreviewCustomFieldsType> = (props) => {
  const { userProfile } = props;

  const {
    settings: {
      customFieldsModel: { jsonSchema, uiSchema },
    },
  } = React.useContext(GenericContext);

  const { current: abortController } = React.useRef(new AbortController());
  React.useEffect(() => () => abortController.abort(), [abortController]);

  // @ts-expect-error
  const patchedJsonSchema = getPatchedJsonSchema(jsonSchema, uiSchema);

  const filledTotal = Object.entries(userProfile.customFields || {}).filter(([key, value]) => {
    if (!patchedJsonSchema.properties[key]) {
      return false;
    }
    return Array.isArray(value) ? value.length : value;
  }).length;

  return (
    <div>
      <div className="panel-menu-item-summary">
        {!!filledTotal &&
          translate('{{ filledTotal }} fields entered', {
            filledTotal,
          })}
        {!filledTotal && translate('All fields empty')}
      </div>
    </div>
  );
};
